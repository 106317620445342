<template>
	<el-card v-loading="tableLoading" class="box-card">
		<div class="title">
			<!-- {{ translateTitle("账户") }} -->
			<Header />
		</div>
		<!-- <Headers /> -->
		<div class="setting-detail">
			<div class="header-title">{{ userInfo.userName || 'Hello Mark' }}
				<p v-if="iframeLink.state==0" class="tip" :class="kycStatusInfo.kycStatus? '':'gray'">
					{{translateTitle('未认证')}}
				</p>
				<p v-if="iframeLink.state==1" class="tip" :class="kycStatusInfo.kycStatus? '':'gray'">
					{{translateTitle('审核中')}}
				</p>
				<p v-if="iframeLink.state==2||iframeLink.state==3" class="tip"
					:class="kycStatusInfo.kycStatus? '':'gray'"><img width="15px"
						src="../../assets/imgs/newImg/icon_gouxuan.png">{{translateTitle('已认证')}}
				</p>
			</div>
		</div>
		<div v-if="userInfo.registerToBank==false">
			<div class="iframe" style="height:auto;width: 100%;" <div class="enter">
				<p class="enterOne">Personal information form</p>
				<p class="enterTwo">Don't worry, we'll keep all of your data on our secure servers and will never
					disclose it to any third parties.</p>
				<div class="enterBottom">
					<p class="start" @click="toPersonal">Start</p>
					<!-- <p class="press">press <span>Enter</span></p> -->
				</div>
			</div>
		</div>
		</div>
		<div v-else>
			<div style="display:flex; justify-content:center; align-items:center; height:100vh;" class="iframe"
				v-if="iframeLink.state==0">
				<iframe id="iframeId" style="width:100%; height:100%;" :src="iframeLink.kycLink"
					frameborder="0"></iframe>
			</div>
			<div class="iframe" style="height:auto;width: 100%;" v-else>
				<div class="enter">
					<!-- <p class="enterOne">Personal information form</p> -->
					<p class="enterTwo">You can proceed to get your own mastercard now</p>
					<div class="enterBottom">
						<p class="start" @click="toCard">Go</p>
						<!-- <p class="press">press <span>Enter</span></p> -->
					</div>
				</div>
			</div>
		</div>
		<!-- <el-row :gutter="20">
    <el-col :span="12">
      <div class="grid-content bg-purple">
        <div class="top common-color"><img width="28" src="../../assets/imgs/ico_kyc_id@2x.png">{{translateTitle('添加KYC身份')}}</div>
        <div v-if="userInfo.poiStatus" class="bottom" ><img width="20" src="../../assets/imgs/dui.png" alt=""></div>
        <div v-else class="bottom" @click="handleClickBtn('add', 1)"><img width="20" src="../../assets/imgs/ico_kyc_more@2x.png" alt=""></div>
      </div>
    </el-col>
    <el-col :span="12">
      <div class="grid-content bg-purple">
        <div class="top common-color"><img width="28" src="../../assets/imgs/ico_kyc_adress@2x.png">{{translateTitle('添加KYC地址')}}</div>
        <div v-if="userInfo.poaStatus" class="bottom" ><img width="20" src="../../assets/imgs/dui.png" alt=""></div>
        <div v-else class="bottom" @click="handleClickBtn('add', 2)"><img width="20" src="../../assets/imgs/ico_kyc_more@2x.png" alt=""></div>
      </div>
    </el-col>
    <el-col :span="12">
      <div class="grid-content bg-purple">
        <div class="top common-color"><img width="28" src="../../assets/imgs/ico_kyc_detail@2x.png">{{translateTitle('查看KYC详情')}}</div>
        <div class="bottom" @click="handleClickBtn('detail', 3)"><img width="20" src="../../assets/imgs/ico_kyc_more@2x.png" alt=""></div>
      </div>
    </el-col>
</el-row> -->

		<!-- 新增编辑kyc -->
		<!--   <DialogPop
      width="600px"
      :title="bindingTitle"
      :visible="Visible"
       :sureText="translateTitle('确定')"
       :closeText="translateTitle('取消')"
      :btnloading="btnloading"
      @onClose="onClose"
      @onSure="onSure('ruleForm')"
    >
      <div class="change-form">
        <el-form
          label-position="top"
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          hide-required-asterisk
        >
          <el-form-item v-if="kycType == '1'" :label="translateTitle('国籍')" prop="nationality">
            <el-select
              v-model="ruleForm.nationality"
              filterable
              clearable
              :placeholder="translateTitle('输入国籍')"
            >
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="kycType == '1'" :label="translateTitle('证件类型')" prop="idType">
            <el-select v-model="ruleForm.idType" :placeholder="translateTitle('请选择')">
              <el-option
                v-for="item in options1"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="kycType == '1'" :label="translateTitle('证件号码')" prop="idNumber">
            <el-input
              v-model="ruleForm.idNumber"
              :placeholder="translateTitle('输入证件号码')"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="kycType == '1'" :label="translateTitle('过期时间')" prop="idExpire">
            <el-date-picker
              v-model="ruleForm.idExpire"
              clearable
              format="yyyy-MM-dd"
              :placeholder="translateTitle('选择日期')"
              type="date"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item :label="translateTitle('文件上传')" prop="filePath">
            <el-upload
              list-type="picture-card"
              :action= "fileUrl+ `/sys/file/upload`"
              :before-upload="handleBeforeUpload"
              class="avatar-uploader"
              :headers="uploadHeader"
              :on-error="handleError"
              :on-success="handleUploadSuccess"
              :show-file-list="false"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <el-image
                style="width: 100%; height: 100%"
                v-if="ruleForm.filePath"
                :src="fileUrl + ruleForm.filePath"
                fit="fill"
              ></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">{{ translateTitle('只能上传文件') }}</div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
    </DialogPop>
    <el-dialog :visible.sync="dialogVisible">
      <img
        width="100%"
        :src="fileUrl + checkRow.filePath"
        alt=""
      />
    </el-dialog> -->
	</el-card>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import {
		getToken
	} from "@/utils/token";
	import Headers from "../../components/accountHeader/index.vue";
	import topHeader from "../../components/topHeader/index"
	import {
		kycStatus,
		userKycDetails
	} from "@/api/kyc";
	import {
		getNational
	} from "@/api/accountManage";
	import axios from "axios";
	import {
		translateTitle
	} from "@/utils/i18n";
	import { getUserInfo, } from '@/api/login'
	export default {
		components: {
			Headers,
			topHeader
		},
		filters: {
			statusFilter(status) {
				const statusMap = {
					0: '未上传',
					1: '审核中',
					2: '银行已拒绝',
					3: '银行审核通过',
				}
				return statusMap[status]
			}
		},
		data() {
			return {
				value1: false,
				total: 20,
				size: 10,
				tableData: [],
				tableLoading: false,
				options1: ["passport", "IdentityCard"],
				uploadHeader: {
					token: getToken(),
				},
				rules: {
					filePath: [{
						required: true,
						message: this.translateTitle("请上传图片"),
						trigger: "change"
					}, ],
					nationality: [{
						required: true,
						message: this.translateTitle("请输入国籍"),
						trigger: "change"
					}, ],
					gender: [{
						required: true,
						message: this.translateTitle("请选择性别"),
						trigger: "change"
					}],
					idNumber: [{
						required: true,
						message: this.translateTitle("请输入证件号码"),
						trigger: "change"
					}, ],
					idType: [{
						required: true,
						message: this.translateTitle("请选择证件类型"),
						trigger: "change"
					}, ],
					idExpire: [{
						required: true,
						message: this.translateTitle("请选择过期时间"),
						trigger: "change"
					}, ],
				},
				bindingTitle: this.translateTitle("新增KYC"),
				ruleForm: {
					fileName: "",
					filePath: "",
					id: "",
					idExpire: "",
					idNumber: "",
					idType: "",
					nationality: "",
					fileType: "",
				},
				activeName: "first",
				pageSize: 10,
				page: 1,
				Visible: false,
				options: [],
				dialogVisible: false,
				btnloading: false,
				checkRow: {},
				kycType: "",
				fileUrl: process.env.NODE_ENV == 'production' ? 'https://api.bankeracard.com/client_side' :
					'http://13.214.25.79:9001/client_side',
				kycStatusInfo: {},
				iframeLink: {},
				userInfo:{}
			};
		},
		// computed: {
		// 	...mapState({
		// 		userInfo: (state) => state.user.user,
		// 	}),
		// },
		mounted() {
			// this.getTableData();
			// this.getKycStatus()
			this.getUserinfo()
			this.userKycDetails()
		},
		methods: {
			translateTitle,
			//获取分页数据
			async getTableData() {
				const data = {
					pageSize: this.pageSize,
					page: this.page,
					baseUserId: 1,
				};
				const loading = this.$loading({
					lock: true,
					text: '',
					spinner: '',
					background: 'rgba(255, 255, 255, 0.8)'
				});
				// this.tableLoading= true
				let res = await KYCPage(data);
				if (res.code == 200) {
					this.tableData = res.data.records;
					this.total = res.data.total;
					// this.tableLoading= false
					loading.close()
				} else {
					this.$message.error(this.translateTitle(res.msg));
				}
			},
			getUserinfo(){
				getUserInfo().then(res=>{
					if (res.code == 200) {
						this.userInfo = res.data
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			//获取kyc状态
			async getKycStatus() {
				let res = await kycStatus();
				if (res.code == 200) {
					this.kycStatusInfo = res.data
				} else {
					this.$message.error(res.msg);
				}
			},
			// kyc iframe
			async userKycDetails() {
				let res = await userKycDetails();
				if (res.code == 200) {
					this.iframeLink = res.data
				} else {
					this.$message.error(res.msg);
				}
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			handleBeforeUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isJPG =
					file.type === 'image/jpeg' ||
					file.type === 'image/jpg' ||
					file.type === 'image/png'
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error(this.translateTitle('格式错误'));
				}
				if (!isLt2M) {
					this.$message.error(this.translateTitle("请选择小于2M的文件"));
				}
				this.loading = true;
				return isJPG && isLt2M;
			},
			handleError() {
				this.loading = false;
				this.$message.error(this.translateTitle("上传失败"));
			},
			handleUploadSuccess(res) {
				this.loading = false;
				if (res.code !== 200) {
					this.$message.warning(res.msg);
					return;
				}
				this.$message.success(this.translateTitle("上传成功"));
				this.ruleForm.filePath = res.data.filePath;
				this.ruleForm.fileName = res.data.fileName;
				// Object.assign(this.uploadRow, res.data)
			},
			handlePictureCardPreview(file) {
				this.dialogVisible = true;
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			showdialog(row) {
				this.dialogVisible = true;
				this.checkRow = row;
			},
			onClose() {
				this.$refs["ruleForm"].resetFields();
				this.Visible = false;
			},
			onSure(ruleForm) {
				let params;
				let _request;
				this.$refs[ruleForm].validate((valid) => {
					if (valid) {
						this.btnloading = true
						addKyc(this.ruleForm).then((res) => {
							if (res.code == 200) {
								this.$message.success(this.translateTitle("操作成功"));
								this.getTableData();
								this.Visible = false;
								this.$refs["ruleForm"].resetFields();
							} else {
								this.$message.error(this.translateTitle(res.msg));
							}
						}).finally(() => {
							this.btnloading = false
						});
						// alert("submit!");
					} else {
						return false;
					}
				});
				this.visible = true;
			},
			handleClickBtn(type, type1, val) {
				this.Visible = true;

				this.getNationalData();
				if (type == "edit") {
					this.kycType = val.fileType;
					this.ruleForm.fileType = val.fileType;
					this.ruleForm = val;

					this.bindingTitle = val.fileType == "2" ? this.translateTitle("编辑KYC地址") : this.translateTitle(
						"编辑KYC身份");
				} else if (type == "add") {
					this.kycType = type1;
					this.ruleForm.fileType = type1;
					type1 == "2" ? this.$router.push('/kycAddress') : this.$router.push('/kycIdentity');
				} else {
					this.$router.push('/kycDetail')
				}
			},
			toPersonal() {
				this.$router.push('/personal')
			},
			toCard() {
				this.$router.push('/card')
			},
			async getNationalData() {
				let res = await getNational();
				this.options = res.data;
			},
			loadFrame() {
				var iframe = document.getElementById('iframeId');
				var iframeDoc = iframe.contentDocument || iframe.contentWindow
				var iframeBody = iframeDoc.getElementsByTagName('body')[0];
				console.log(iframe, 'iframe')
				console.log(iframeDoc, 'iframe')
				console.log(iframeBody, 'iframe')
				// return
				//    const iframe = document.getElementById("iframeId").getElementsByClassName('base-layout-container');
				// const Do = (iframe.contentWindow || iframe.contentDocument)
				// console.log(iframe,'iframe')
				// console.log(Do.document.)
				// const body = iframe.contentWindow.document.body;
				// const cssLink = document.createElement("link");
				// cssLink.href = "/vue项目名称/iframe-css/iframe.css";
				// cssLink.rel = "stylesheet";
				// cssLink.type = "text/css";
				// body.appendChild(cssLink);
			},
		},
	};
</script>

<style lang="less" scoped>
	@media (min-width: 769px) {
		::v-deep {
			.iframe {
				width: 100%;
				/* 设置容器的宽度 */
			}

			iframe .base-layout-container {
				width: 100%;
				/* 设置iframe元素的宽度为容器的百分之百 */
				height: 300px;
				/* 设置iframe元素的高度为300像素 */
			}
		}
	}
	::v-deep .iframe #iframe .app-view-container .base-layout-container{
		width: 100% !important;
	}
	.title {
		font-size: 26px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: bold;
		color: #31363e;
		margin-bottom: 20px;
		text-align: right;
	}

	.setting-detail {
		font-size: 22px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: bold;
		color: #31363e;
		margin-top: 30px;
		margin-bottom: 30px;
		display: flex;

		.header-title {
			font-size: 36px;
			font-family: Microsoft YaHei-Bold, Microsoft YaHei;
			font-weight: bold;
			color: #31363E;
			line-height: 26px;
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			justify-content: center;

			.tip {
				margin-left: 24px;
				background: rgba(55, 34, 0, 0.1);
				border-radius: 4px;
				color: rgba(144, 117, 54, 1);
				padding: 4px 10px;
				border: 1px solid rgba(55, 34, 0, 0.8);
				font-size: 15px;
				font-family: Microsoft YaHei-Bold, Microsoft YaHei;
				font-weight: bold;
				line-height: 26px;

				img {
					margin-right: 5px;
					vertical-align: middle;
				}
			}

			.gray {
				background: rgba(55, 34, 0, 0.1);
				border: 1px solid rgba(55, 34, 0, 0.8);
			}
		}
	}

	.succ {
		color: #3ad3b9;
	}

	.err {
		color: #d33a3a;
	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-col {
		border-radius: 4px;
		margin-bottom: 20px;
	}

	.bg-purple {
		background: #F7F6F5;
	}

	.common-color {
		color: #786234 !important;
	}

	.grid-content {
		border-radius: 10px;
		padding: 30px;
		min-height: 36px;

		.top {
			img {
				vertical-align: middle;
				margin-right: 10px;
				margin-top: -3px;
			}

			font-size: 18px;
			font-family: Microsoft YaHei-Bold,
			Microsoft YaHei;
			font-weight: 700;
		}

		.bottom {
			margin-top: 50px;
			text-align: right;
		}
	}


	// .iframe {
	// 		iframe {
	// 			width: 100%;
	// 			height: 500px;
	// 		}

	// 		width: 80%;
	// 		margin: 0 auto;
	// 		height: 500px;
	// 	}

	::v-deep .base-layout-container {
		width: 100% !important;
	}

	::v-deep.el-card {
		border: none;
	}

	::v-deep.el-card.is-always-shadow {
		box-shadow: none;
	}

	.enter {
		text-align: center;
		padding: 20% 10%;
	}

	.enterOne {
		font-weight: bold;
		font-size: 35px;
		margin-bottom: 20px;
	}

	.enterTwo {
		font-size: 25px;
		line-height: 35px;
		color: #1E3E43;
	}

	.enterBottom {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 20px;

		.start {
			background: black;
			padding: 15px;
			color: white;
			font-weight: bold;
			letter-spacing: 1px;
			border-radius: 5px;
			cursor: pointer;
		}

		.press {
			margin-left: 10px;

			span {
				font-weight: bolder;

			}
		}
	}
</style>